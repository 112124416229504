import React from 'react';
import "./Financiamento.scss";
import { Link } from "react-router-dom";
import finanPic from "../../assets/images/financiamento/truck-driver.jpg";

import { FaWhatsapp } from "react-icons/fa";

function Financimento() {
    return (
        <div className="financiamento">
            <div className="financiamento__pic">
                <h1>Financiamento</h1>
                <img src={finanPic} alt="CAMINHAO" />
            </div>
            <div className="financiamento__container">
                <div className="financiamento__content">
                    <h1 style={{ marginTop: "4rem"}}>Dados Pessoais</h1>
                    <p><b>CNH – D OU E</b> (CATEGORIA D OU E PARA CAMINHÕES TRUCKS OU CAVALOS MECÂNICOS)</p>
                    <p><b>RG E CPF</b> (DO FINANCIADO E DO CÔNJUGE)</p>
                    <p><b>ENDEREÇO COMPLETO E CEP</b></p>
                    <p><b>TELEFONES (FIXO E CELULAR)</b></p>
                </div>
                <div className="financiamento__content">
                    <h1>Comprovação de Renda</h1>
                    <p><b>LOCAL DE AGREGAMENTO</b> (LOCAL DE AGREGAMENTO OU ONDE TRABALHA)</p>
                    <p><b>NOME COMPLETO DA EMPRESA E CNPJ</b></p>
                    <p><b>ENDEREÇO COMPLETO DA EMPRESA E CEP</b></p>
                    <p><b>TELEFONES FIXOS E CELULARES</b></p>
                    <p><b>NOME DO INFORMANTE</b> (NOME DA PESSOA QUE VAI DAR  A INFORMAÇÃO)</p>
                    <p><b>FATURAMENTO BRUTO MÉDIO MENSAL</b></p>
                    <p><b>CARGO OCUPACIONAL:</b></p>
                    <p><b>CARGO NA EMPRESA:</b></p>
                    <p><b>1º AGREGADO</b></p>
                    <p><b>2º PRESTADOR DE SERVIÇO</b></p>
                    <p><b>3º TERCEIRIZADO</b></p>
                    <p><b>4º FUNCIONÁRIO</b></p>
                    <p><b>5º CHEFE DE TRANSPORTES</b></p>
                    <p><b>6º DONO DA EMPRESA</b>(CASO SEJA DONO DA EMPRESA, PASSAR NOME E TELEFONE DO CONTADOR)</p>
                </div>
                <div className="financiamento__content">
                    <h1>Referências</h1>
                    <p>INFORME NOME E TELEFONE DE <b>DUAS PESSOAS</b>, PODE SER PARENTE OU AMIGO.</p>
                    <p><b>DUAS REFERÊNCIAS COMERCIAIS</b> COM TELEFONE (PODE SER: POSTO DE GASOLINA, LOJA DE PEÇAS, PNEUS, FINANCEIRAS, LOJAS DE ACESSÓRIOS) ETC…</p>
                    <p><b>REFERÊNCIAS BANCÁRIAS</b> (NOME DO BANCO – AGÊNCIA – CONTA – TEMPO DE CONTA</p>
                </div>
                <div className="financiamento__content">
                    <h1>Propriedades</h1>
                    <p>INFORME MODELO E PLACA DE VEÍCULOS DE SUA PROPRIEDADE</p>
                </div>
                <div className="financiamento__content">
                    <h1>Valor e Prazo</h1>
                    <p>VALOR QUE QUER FINANCIAR E O PRAZO</p>
                </div>
                <p style={{ textAlign: "center" }}>ENVIE TODOS OS DOCUMENTOS E INFORMES QUE FAREMOS A SOLICITAÇÃO DO FINANCIAMENTO DO CAMINHÃO, QUALQUER DÚVIDA BASTA LIGAR OU CHAMAR NO WHATSAPP: FINANCIAR CAMINHÃO NUNCA FOI TÃO FÁCIL.</p>
                <div className="financiamento__link">
                    <h1>FAÇA A SOLICITAÇÃO DO FINANCIAMENTO AGORA:</h1>
                    <div>
                        <Link to="https://api.whatsapp.com/send/?phone=5566999815612&text=Olá, tenho interesse no financiamento" target="_blank"><FaWhatsapp />Financiamento</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Financimento;
