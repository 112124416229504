import React, { useEffect, useState } from "react";
import "./Home.scss";
import spinner from "../../assets/images/ring-resize.svg";
import api from "../../config/configApi";
import { Carousel } from "../../components/Carousel/Carousel";
import Cards from "../../components/Cards/Cards";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
// import { showOnScroll } from "../../components/Common/Helpers";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import picFinan from "../../assets/images/financiamento/truck-driver.jpg";
import Iveco from "../../assets/images/brands/Iveco.png";
import Librelato from "../../assets/images/brands/Librelato.jpg";
import Mercedes from "../../assets/images/brands/Mercedes.png";
import Randon from "../../assets/images/brands/Randon.jpg";
import Scania from "../../assets/images/brands/Scania.png";
import Volvo from "../../assets/images/brands/Volvo.png";

import AboutUs from "../../assets/images/truck-aboutus.png";
import Modal from "../../components/Modal";

import Glider from 'react-glider';
import 'glider-js/glider.min.css';

function Home() {
  const [veiculos, setVeiculos] = useState({
    AnoFabricacao: "",
    AnoModelo: "",
    Cambio: "",
    Carroceria: "",
    Combustivel: "",
    Cor: "",
    CreatedAt: "",
    Direcao: "",
    IdCategoria: "",
    IdMarca: "",
    IdVeiculo: "",
    IdView: "",
    Imagem: "",
    Km: "",
    Modelo: "",
    NomeMarca: "",
    Observacao: "",
    Pic: "",
    Tipo: "",
    Tracao: "",
    Valor: ""
  });

  const [ofertas, setOfertas] = useState({
    IdVeiculo: "",
    NomeMarca: "",
    AnoFabricacao: "",
    AnoModelo: "",
    Modelo: "",
    Tracao: "",
    Km: "",
    Valor: "",
    Imagem: "",
    Oferta: "",
    IdView: ""
  })

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  // useEffect(() => {
  //   showOnScroll("home--models");
  //   showOnScroll("cards");
  // }, [])

  useEffect(() => {
    const getOfertas = async () => {
      try {
        const response = await api.get("/api/veiculos/getOfertas");
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }

    const getVeiculos = async () => {
      try {
        const response = await api.get(`/api/veiculos/getRecomendados`);
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }

    const fetchBoth = async () => {
      setLoadingModal(true);
      const sales = await getOfertas();
      setOfertas(sales);
      setLoadingModal(false);

      setLoading(true);
      const vehicles = await getVeiculos();
      setVeiculos(vehicles);
      setLoading(false);
    }

    fetchBoth();
    setTimeout(() => { setOpenModal(true) }, 2000);
  }, [])

  function valor(Valor) {
    let valor = Valor.toString();

    if (valor == 1) {
      valor = "Consulte";
      return valor;
    } else {
      if (valor.length === 6) {
        const a = valor.slice(0, 3);
        const b = valor.slice(3, valor.length + 1);
        valor = a.concat(".", b);
      }

      if (valor.length === 5) {
        const a = valor.slice(0, 2);
        const b = valor.slice(2, valor.length + 1);
        valor = a.concat(".", b);
      }
      return valor;
    }
  }

  function transformKm(Km) {
    let km = Km.toString();

    if (km == 1) {
      km = "Consulte";
      return km;
    } else {
      if (km.length === 7) {
        const a = km.slice(0, 1);
        const b = km.slice(1, 4);
        const c = km.slice(4, km.length + 1);
        km = a.concat(".", b, ".", c);
      }

      if (km.length === 6) {
        const a = km.slice(0, 3);
        const b = km.slice(3, km.length + 1);
        km = a.concat(".", b);
      }

      if (km.length === 5) {
        const a = km.slice(0, 2);
        const b = km.slice(2, km.length + 1);
        km = a.concat(".", b);
      }
      return km;
    }
  }

  const handleView = (value) => {
    api.post("/api/views/updateView/" + value)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  return (
    <>
      <Modal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        {loadingModal &&
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={spinner} alt="Loading..." />
          </div>
        }

        {!loadingModal && ofertas.length > 0 &&
          <Glider
            className="glider-container"
            draggable
            hasArrows
            hasDots
            slidesToShow={1}
            scrollLock
          >
            {ofertas.map(item => (
              <Link className="sale" key={item.IdVeiculo} onClick={() => handleView(item.IdVeiculo)} to={`/veiculo/${item.IdVeiculo}`}>
                <div className="sale--pic">
                  <img className="sale--img" src={item.Imagem} alt={item.Modelo} />
                </div>
                <div className="sale--content">
                  <div className="sale--up">
                    <h1 className="sale--title">OFERTA DO DIA</h1>
                    <h1 className="sale--title mid">OFERTA DO DIA</h1>
                    <h1 className="sale--title">OFERTA DO DIA</h1>
                  </div>
                  <div className="sale--info">
                    <h2 className="sale--model">{item.NomeMarca} {item.Modelo}</h2>
                    <span className="sale--desc">{item.Tracao} - {item.AnoFabricacao}/{item.AnoModelo} - KM {transformKm(item.Km)}</span>
                  </div>
                  <div className="sale--price">
                    <span className="sale--rs">R$</span>
                    <h1 className="sale--value">{valor(item.Valor)}</h1>
                    <span className="sale--cents">,00</span>
                  </div>
                  <div className="sale--bottom">
                    <div className="sale--social">
                      <FaFacebookSquare />
                      <FaInstagramSquare />
                      <FaWhatsappSquare />
                      <div className="sale--contact">
                        <span>veiculoseciamt</span>
                        <span>(66) 99981-5612</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Glider>
        }
      </Modal>
      <Carousel />
      <main className="home">
        <div className="home--container">
          <div className="home--models">
            <p className="models--title">
              Conheça nossos modelos em destaque
            </p>
            <div className="models--line">
              <span className="line first"></span>
              <span className="line second"></span>
              <span className="line third"></span>
            </div>

            {loading &&
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={spinner} alt="Loading..." />
              </div>
            }

            {!loading && veiculos.length > 0 &&
              <div className="recomendados__container">
                <section className="cards">
                  {veiculos.map(item => (
                    <Cards key={item.IdVeiculo} {...item} />
                  ))}
                </section>
              </div>
            }

            {!loading && veiculos.length === 0 &&
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Nenhum registro encontrado!</p>
              </div>
            }

            <Link to="/estoque" onClick={() => window.scroll(0, 0)} className="models--button">
              Ver estoque completo <BsArrowRightShort />
            </Link>
          </div>
          <div className="home--filter">
            <h1 className="filter--title">Fitrar pela <span className="brand--title">Marca</span></h1>
            <div className="home--brands">
              <Link to="estoque?marca=9" target="_blank" className="brand--link">
                <img src={Iveco} alt="Iveco" />
              </Link>
              <Link to="estoque?marca=13" target="_blank" className="brand--link">
                <img src={Librelato} alt="Librelato" />
              </Link>
              <Link to="estoque?marca=15" target="_blank" className="brand--link">
                <img src={Mercedes} alt="Mercedes" />
              </Link>
              <Link to="estoque?marca=21" target="_blank" className="brand--link">
                <img src={Randon} alt="Randon" />
              </Link>
              <Link to="estoque?marca=24" target="_blank" className="brand--link">
                <img src={Scania} alt="Scania" />
              </Link>
              <Link to="estoque?marca=27" target="_blank" className="brand--link">
                <img src={Volvo} alt="Volvo" />
              </Link>
            </div>
          </div>
        </div>

        <div className="home--financiamento">
          <ParallaxProvider>
            <ParallaxBanner
              layers={[{ image: picFinan, speed: 5 }]}
            >
              <div className="absolute inset-0 flex items-center justify-center" style={{ position: "relative", height: "300px" }}>
                <div className="home--container" style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                  <h1 className="text-8xl text-white font-thin">Financiamento</h1>
                  <p className="text-8xl text-white font-thin">A Veículos & Cia - Caminhões e Carretas facilita ao máximo o seu financiamento para proporcionar parcelas que cabem no seu bolso. <Link to="/financiamento" onClick={() => window.scroll(0,0)}>Saiba+</Link></p>
                </div>
              </div>
            </ParallaxBanner>
          </ParallaxProvider>
        </div>

        <div className="home--about" id="quem-somos">
          <div className="home--container">
            <div className="about--content">
              <div className="about--info">
                <h1 className="about--title">
                  Sobre a Veículos & Cia
                </h1>
                <p className="about--description">
                  Empresa no ramo de venda de veículos com mais de 16 anos de mercado,
                  estabelecida às margens da rodovia BR 364, perímetro urbano do município
                  de JACIARA-MT, atuando no seguimento de veículos pesados, e para melhor 
                  atendê-lo, vem inovando a cada dia.
                  Disponibilizando seus veículos em vários sites e redes sociais na internet,
                  como: Facebook, Instagram, WhatsApp e Youtube, para que você cliente receba
                  nossas ofertas. Trabalhamos em parcerias com diversas transportadoras em todo
                  o Brasil, trazendo até você as melhores condições de preços para aquisição de
                  seu veículos pesados. Trabalhamos na venda por unidades ou por lotes, sempre
                  com preço diferenciado e qualidade. Trabalhamos com vários bancos e formas de
                  financiamentos. Disponibilizamos crédito para financiamento do 1° CAMINHÃO,
                  buscamos também as melhores condições de financiamentos para essa linha de
                  crédito. Venha nos visitar, teremos o maior prazer em atender você cliente.
                </p>
              </div>
              <div className="about--images">
                <img src={AboutUs} alt="Caminhão" width="760px" />
                <div className="circle--shape">
                  <p>Mais de 16 Anos de Mercado</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="instagramWrapper">
          <iframe width="100%" height="1500px" src="https://www.instagram.com/veiculosecia/embed/" scrolling="no" frameBorder="0"></iframe>
        </div>
      </main>
    </>
  )
}

export default Home;