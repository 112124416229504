import React, { useState } from "react"
import "./Header.scss";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FaWhatsapp } from "react-icons/fa";

function Header() {

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    if(window.screen.width <= 1100) {
      setIsActive(current => !current);
      document.body.style.overflow = document.body.style.overflow == "hidden" ? "initial" : "hidden";
    }
    window.scroll(0,0);
  }

  return (
    <>
      <header className="header">
        <div className="header--container">

          <Link to="/" onClick={() => window.scroll(0,0)}>
            <img src={Logo} alt="Veículos e CIA" className="header--logo" width="309" height="89" />
          </Link>

          <div className={isActive ? "menu--section on" : "menu--section"}>
            <div
              className="menu--toggle"
              onClick={handleClick}
            >
              <div className="one" />
              <div className="two" />
              <div className="three" />
            </div>
            <nav className="header--items">
              <ul className="header--list">
                <li className="list--item">
                  <Link to="/" className="list--link" onClick={handleClick}>Início</Link>
                </li>
                <li className="list--item">
                  <HashLink onClick={handleClick} smooth to="/#quem-somos" className="list--link">Sobre nós</HashLink>
                </li>
                <li className="list--item">
                  <Link to="/financiamento" onClick={handleClick} className="list--link">Financiamento</Link>
                </li>
                <li className="list--item">
                  <Link to="/estoque" onClick={handleClick} className="list--link">Estoque</Link>
                </li>
                <li className="list--item">
                  <Link to="/contato" onClick={handleClick} className="list--link">Contato</Link>
                </li>
                <li className="list--item">
                  <Link className="contact--link" to="https://api.whatsapp.com/send/?phone=5566999815612&text=Olá, estou interessado em adquirir um veículo" target="_blank">
                    <FaWhatsapp className="contact--icon" /> (66) 99981-5612
                  </Link>
                </li>
              </ul>
            </nav>
          </div>          

        </div>
      </header>
    </>
  )
}

export default Header;