import React, { useState, useRef } from "react";
import "../../styles/Cadastro.scss";
import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";
import { v4 } from "uuid";
import Sidebar from "../../components/Sidebar";
import { IoIosAddCircle } from "react-icons/io";
import { InputNumber } from "primereact/inputnumber";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import api from "../../config/configApi";
import toast, { Toaster } from "react-hot-toast";

function CadastrarCarreta() {
    const [uploading, setUploading] = useState(false);
    const [images, setImages] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const fileInputRef = useRef(null);

    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    )

    const navigate = useNavigate();

    function selectFiles() {
        fileInputRef.current.click();
    }

    function onFileSelect(e) {
        setImages(e.target.files);

        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        })

        setSelectedImages(imagesArray);
    }

    function onDragOver(e) {
        e.preventDefault();
        setIsDragging(true);
        e.dataTransfer.dropEffect = "copy";
    }

    function onDragLeave(e) {
        e.preventDefault();
        setIsDragging(false);
    }

    function onDrop(e) {
        e.preventDefault();
        setIsDragging(false);

        setImages(e.dataTransfer.files);

        const selectedFiles = e.dataTransfer.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        })

        setSelectedImages(imagesArray);
    }

    const [userForm, setUserForm] = useState({
        IdUsuario: currentUser.IdUsuario,
        categoria: 2,
        marca: "0",
        anoFabricacao: "",
        anoModelo: "",
        tipo: "0",
        modelo: "0",
        direcao: "0",
        cor: "0",
        combustivel: "0",
        cambio: "0",
        tracao: "0",
        carroceria: "0",
        km: 1,
        valor: 1,
        observacao: "",
        pasta: "",
        imagem: "",
        oferta: 0,
    });

    const [errors, setErrors] = useState({
        marca: null,
        anoFabricacao: null,
        anoModelo: null,
        tipo: null,
        modelo: null,
        cor: null,
        valor: null,
        observacao: null,
        image: null
    });

    const handleChangeValues = (e) => {
        setUserForm((prevValue) => ({
            ...prevValue,
            [e.target.name]: e.target.value,
        }))
    }

    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) e.preventDefault()
    }

    const [status, setStatus] = useState({
        type: "",
        msg: ""
    });

    const handleClick = async (e) => {
        e.preventDefault();

        let formIsValid = true;

        if (userForm.IdUsuario === null) {
            formIsValid = false;
        }

        if (userForm.marca === "0") {
            setErrors((prev) => ({ ...prev, marca: "Marca é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, marca: null }));
        }

        if (userForm.anoFabricacao.toString().length < 4) {
            setErrors((prev) => ({ ...prev, anoFabricacao: "Ano Fabricação é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, anoFabricacao: null }));
        }

        if (userForm.anoModelo.toString().length < 4) {
            setErrors((prev) => ({ ...prev, anoModelo: "Ano Modelo é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, anoModelo: null }));
        }

        if (userForm.tipo === "0") {
            setErrors((prev) => ({ ...prev, tipo: "Tipo é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, tipo: null }));
        }

        if (userForm.modelo === "0") {
            setErrors((prev) => ({ ...prev, modelo: "Modelo é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, modelo: null }));
        }

        if (userForm.cor === "0") {
            setErrors((prev) => ({ ...prev, cor: "Cor é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, cor: null }));
        }

        if (userForm.valor === null) {
            setErrors((prev) => ({ ...prev, valor: "Valor é obrigatório! Deixe valor R$ 1,00 para ficar como consulte." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, valor: null }));
        }

        if (isEmpty(userForm.observacao)) {
            setErrors((prev) => ({ ...prev, observacao: "Observação é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, observacao: null }));
        }

        if (images === undefined) {
            setErrors((prev) => ({ ...prev, image: "Imagem é obrigatório." }));
            formIsValid = false;
        } else if (images.length > 15) {
            setErrors((prev) => ({ ...prev, image: "Selecione ate 15 imagens!!!" }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, image: null }));
        }

        if (!formIsValid) return;

        e.currentTarget.disabled = true;

        // ENVIANDO IMAGENS PARA STORAGE DO FIREBASE
        let result;
        let exists;
        let pathName = v4().substring(0, 10);
        userForm.pasta = pathName;
        // verifica se existe pasta com o nome igual
        try {
            exists = await Photos.pathExists(pathName);
        } catch (err) {
            console.log(err);
            alert(JSON.stringify(err));
        }

        if (exists === false) {
            setUploading(true);
            // faz o envio das imagens
            try {
                result = await Photos.insert(pathName, images);
            } catch (err) {
                console.log(err);
                alert(JSON.stringify(err));
            }
            setUploading(false);

            if (result === true) {
                setErrors((prev) => ({ ...prev, image: "Algo deu errado no upload da imagem!!!" }));
            } else {
                userForm.imagem = result;
            }
        } else {
            pathName = v4().substring(0, 10);
            userForm.pasta = pathName;

            setUploading(true);
            // faz o envio das imagens
            try {
                result = await Photos.insert(pathName, images);
            } catch (err) {
                console.log(err);
                alert(JSON.stringify(err));
            }
            setUploading(false);

            if (result === true) {
                setErrors((prev) => ({ ...prev, image: "Algo deu errado no upload da imagem!!!" }));
            } else {
                userForm.imagem = result;
            }
        }

        if (result !== true) {
            await api.post("/api/veiculos/addVeiculo", userForm)
                .then((response) => {
                    setStatus({ type: "success", msg: response.data.msg });
                    toast.success(response.data.msg);
                    setTimeout(() => navigate(`/area-usuario/${currentUser.IdUsuario}`), 3000);
                }).catch((err) => {
                    if (err.response) {
                        toast.error(err.response.data);
                        localStorage.clear();
                        setTimeout(() => navigate("/login"), 3000);
                    } else {
                        setStatus({ type: "error", msg: "Erro: Tente novamente mais tarde!" });
                        toast.error("Erro: Tente novamente mais tarde!");
                    }
                })
        }
    }

    return (
        <>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                />
            </div>
            <Sidebar id={currentUser.IdUsuario} />
            <div className="cadastro">
                <div className="cadastro__container">
                    <div className="cadastro__top">
                        <h1 className="cadastro__titulo">
                            <span><IoIosAddCircle /></span>Cadastrar carreta
                        </h1>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="input__group">
                                <input
                                    name="categoria"
                                    value={"CARRETA"}
                                    className="input__field disabled"
                                    placeholder="CARRETA"
                                    disabled
                                />

                                <label htmlFor="categoria" className="input__label">Categoria</label>
                            </div>

                            <div className="input__group">
                                <select
                                    name="marca"
                                    className="input__field"
                                    value={userForm.marca}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Marca)</option>
                                    <option value="1">BMW</option>
                                    <option value="2">CHEVROLET</option>
                                    <option value="3">DAF</option>
                                    <option value="4">FACCHINI</option>
                                    <option value="5">FORD</option>
                                    <option value="6">GUERRA</option>
                                    <option value="7">HONDA</option>
                                    <option value="8">HYUNDAI</option>
                                    <option value="9">IVECO</option>
                                    <option value="10">JEEP</option>
                                    <option value="11">KIA</option>
                                    <option value="12">LAND ROVER</option>
                                    <option value="13">LIBRELATO</option>
                                    <option value="14">MAN</option>
                                    <option value="15">MERCEDES-BENZ</option>
                                    <option value="16">MITSUBISHI</option>
                                    <option value="17">NISSAN</option>
                                    <option value="18">NOMA</option>
                                    <option value="19">PASTRE</option>
                                    <option value="20">RAM</option>
                                    <option value="21">RANDON</option>
                                    <option value="22">RODOFLEX</option>
                                    <option value="23">ROSSETTI</option>
                                    <option value="24">SCANIA</option>
                                    <option value="25">TOYOTA</option>
                                    <option value="26">VOLKSWAGEN</option>
                                    <option value="27">VOLVO</option>
                                </select>

                                <label htmlFor="marca" className="input__label">Marca</label>
                                {errors?.marca && (
                                    <p className="error__message">{errors?.marca}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <input
                                    name="anoFabricacao"
                                    className="input__field"
                                    type="text"
                                    maxLength="4"
                                    value={userForm.anoFabricacao}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChangeValues}
                                />

                                <label htmlFor="anoFabricacao" className="input__label">Ano Fabricação</label>
                                {errors?.anoFabricacao && (
                                    <p className="error__message">{errors?.anoFabricacao}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <input
                                    name="anoModelo"
                                    className="input__field"
                                    type="text"
                                    maxLength="4"
                                    value={userForm.anoModelo}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChangeValues}
                                />

                                <label htmlFor="anoModelo" className="input__label">Ano Modelo</label>
                                {errors?.anoModelo && (
                                    <p className="error__message">{errors?.anoModelo}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <select
                                    name="tipo"
                                    className="input__field"
                                    value={userForm.tipo}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione o Tipo)</option>
                                    <option value="BITREM">BITREM</option>
                                    <option value="BITREM 9 EIXOS">BITREM 9 EIXOS</option>
                                    <option value="DOLLY">DOLLY</option>
                                    <option value="JULIETA">JULIETA</option>
                                    <option value="RODOTREM">RODOTREM</option>
                                    <option value="SEMI-REBOQUE">SEMI-REBOQUE</option>
                                    <option value="TRITREM">TRITREM</option>
                                </select>

                                <label htmlFor="tipo" className="input__label">Tipo</label>
                                {errors?.tipo && (
                                    <p className="error__message">{errors?.tipo}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <select
                                    name="modelo"
                                    className="input__field"
                                    value={userForm.modelo}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione o Modelo)</option>
                                    <option value="ALGODOEIRO">ALGODOEIRO</option>
                                    <option value="AUTO ESCOLA">AUTO ESCOLA</option>
                                    <option value="BASCULANTE">BASCULANTE</option>
                                    <option value="BAU BEBIDAS">BAU BEBIDAS</option>
                                    <option value="BAU FURGAO">BAU FURGAO</option>
                                    <option value="BAU SIDER">BAU SIDER</option>
                                    <option value="BOBINEIRO">BOBINEIRO</option>
                                    <option value="BOIADEIRO">BOIADEIRO</option>
                                    <option value="CANAVIEIRO">CANAVIEIRO</option>
                                    <option value="CARGA SECA">CARGA SECA</option>
                                    <option value="CARREGA TUDO">CARREGA TUDO</option>
                                    <option value="CAVAQUEIRA">CAVAQUEIRA</option>
                                    <option value="CEGONHA">CEGONHA</option>
                                    <option value="CEREALEIRO">CEREALEIRO</option>
                                    <option value="CEREALEIRO HOPPER">CEREALEIRO HOPPER</option>
                                    <option value="CHASSI">CHASSI</option>
                                    <option value="CONTAINER">CONTAINER</option>
                                    <option value="DOLLY">DOLLY</option>
                                    <option value="DOLLY 1 EIXO">DOLLY 1 EIXO</option>
                                    <option value="DOLLY 2 EIXO">DOLLY 2 EIXO</option>
                                    <option value="EXTENSIVA">EXTENSIVA</option>
                                    <option value="FLORESTAL">FLORESTAL</option>
                                    <option value="FRANGUEIRA">FRANGUEIRA</option>
                                    <option value="FRIGORIFICO">FRIGORIFICO</option>
                                    <option value="GAIOLA de GAS">GAIOLA de GAS</option>
                                    <option value="GRANELEIRO">GRANELEIRO</option>
                                    <option value="JULIETA ROLL ON">JULIETA ROLL ON</option>
                                    <option value="KIT SIDER">KIT SIDER</option>
                                    <option value="LAGARTIXA">LAGARTIXA</option>
                                    <option value="PORTA-CONTAINER">PORTA-CONTAINER</option>
                                    <option value="PRANCHA">PRANCHA</option>
                                    <option value="REBOQUE FLEX 2 EIXOS">REBOQUE FLEX 2 EIXOS</option>
                                    <option value="REBOQUE FLEX 3 EIXOS">REBOQUE FLEX 3 EIXOS</option>
                                    <option value="REBOQUE FLEX 4 EIXOS">REBOQUE FLEX 4 EIXOS</option>
                                    <option value="Roll ON OFF">Roll ON OFF</option>
                                    <option value="SEMI REBOQUE FLEX">SEMI REBOQUE FLEX</option>
                                    <option value="SILO">SILO</option>
                                    <option value="SUINEIRA">SUINEIRA</option>
                                    <option value="TANQUE AÇO">TANQUE AÇO</option>
                                    <option value="TANQUE ALUMINIO">TANQUE ALUMINIO</option>
                                    <option value="TANQUE GASES GLP">TANQUE GASES GLP</option>
                                    <option value="TANQUE INOX">TANQUE INOX</option>
                                    <option value="TANQUE ISOTERMICO">TANQUE ISOTERMICO</option>
                                </select>

                                <label htmlFor="modelo" className="input__label">Modelo</label>
                                {errors?.modelo && (
                                    <p className="error__message">{errors?.modelo}</p>
                                )}
                            </div>

                        </div>

                        <div className="row">
                            <div className="input__group">
                                <select
                                    name="cor"
                                    className="input__field"
                                    value={userForm.cor}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Cor)</option>
                                    <option value="Azul">Azul</option>
                                    <option value="Amarelo">Amarelo</option>
                                    <option value="Branco">Branco</option>
                                    <option value="Preto">Preto</option>
                                    <option value="Prata">Prata</option>
                                    <option value="Bordo">Bordo</option>
                                    <option value="Cinza">Cinza</option>
                                    <option value="Dourado">Dourado</option>
                                    <option value="Laranja">Laranja</option>
                                    <option value="Marrom">Marrom</option>
                                    <option value="Verde">Verde</option>
                                    <option value="Vermelho">Vermelho</option>
                                    <option value="Vinho">Vinho</option>
                                    <option value="Rosa">Rosa</option>
                                </select>

                                <label htmlFor="cor" className="input__label">Cor</label>
                                {errors?.cor && (
                                    <p className="error__message">{errors?.cor}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <InputNumber
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    name="valor"
                                    onValueChange={handleChangeValues}
                                    value={userForm.valor}
                                />

                                <label htmlFor="valor" className="input__label">Valor</label>
                                {errors?.valor && (
                                    <p className="error__message">{errors?.valor}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <textarea
                                    name="observacao"
                                    rows={4}
                                    className="input__area"
                                    onChange={handleChangeValues}
                                    maxLength="600"
                                    placeholder="Descreva informações que serão exibidas no anúncio para ajudar a sua venda..."
                                />

                                <label htmlFor="observacao" className="input__label">Observação</label>
                                {errors?.observacao && (
                                    <p className="error__message">{errors?.observacao}</p>
                                )}
                            </div>
                        </div>

                        <div className="drag">
                            <div
                                className="drag__area"
                                onDragOver={onDragOver}
                                onDragLeave={onDragLeave}
                                onDrop={onDrop}
                            >
                                {isDragging ? (
                                    <span className="select">Solte imagens aqui</span>
                                ) : (
                                    <>
                                        Arraste e solte imagens aqui ou {" "}
                                        <span className="select" role="button" onClick={selectFiles}>Selecione</span>
                                    </>
                                )}

                                <input
                                    name="image"
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={onFileSelect}
                                />
                            </div>
                            <div className="container">
                                {selectedImages.map((image, index) => (
                                    <div key={index} className="image">
                                        <img src={image} alt="upload" />
                                    </div>
                                ))}
                            </div>
                            {uploading &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>"Enviando imagens para nuvem..."</p>
                                    <img src={spinner} alt="spinner" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                                </div>
                            }
                            {errors?.image && (
                                <p className="error__message">{errors?.image}</p>
                            )}
                        </div>
                        
                        <div className="cadastro__button">
                            <button className="btn btn-secondary" type="button" onClick={() => navigate(-1)}>Cancelar</button>
                            <button className="btn btn-success" type="button" onClick={handleClick}>Salvar</button>
                        </div>
                    </form>
                    {status.type === "success" ? <p style={{ color: "green", padding: "1rem" }}>{status.msg}</p> : ""}
                    {status.type === "error" ? <p style={{ color: "red", padding: "1rem" }}>{status.msg}</p> : ""}
                </div>
            </div>
        </>
    )
}

export default CadastrarCarreta;
