import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PaginaBase from "./pages/PaginaBase";
import Login from "./pages/Login";
import CadastrarCaminhao from "./pages/CadastrarCaminhao";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Home from "./pages/Home";
import Estoque from "./pages/Estoque";
import Veiculo from "./pages/Veiculo";
import Contato from "./pages/Contato";
import AreaUsuario from "./pages/AreaUsuario";
import EditarCaminhao from "./pages/EditarCaminhao";
import CadastrarCarreta from "./pages/CadastrarCarreta";
import DeletarCaminhao from "./pages/DeletarCaminhao";
import EditarCarreta from "./pages/EditarCarreta";
import DeletarCarreta from "./pages/DeletarCarreta";
import CadastrarCarro from "./pages/CadastrarCarro";
import EditarCarro from "./pages/EditarCarro";
import DeletarCarro from "./pages/DeletarCarro";
import Redirecionar from "./pages/Redicionar";
import NotFound from "./pages/NotFound";
import Contatos from "./pages/Contatos";
import Financimento from "./pages/Financiamento";

function AppRoutes() {
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />
    }

    return children;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<PaginaBase />}>
          <Route index element={<Home />} />
          <Route path="financiamento" element={<Financimento />} />
          <Route path="estoque" element={<Estoque />} />
          <Route path="veiculo/:id" element={<Veiculo />} />
          <Route path="contato" element={<Contato />} />
        </Route>
        <Route path="contatos" element={<Contatos />} />
        <Route path="login" element={<Login />} />
        <Route path="redirect" element={<ProtectedRoute><Redirecionar /></ProtectedRoute>} />
        <Route path="area-usuario/:id" element={<ProtectedRoute><AreaUsuario /></ProtectedRoute>} />
        <Route path="cadastrar-caminhao" element={<ProtectedRoute><CadastrarCaminhao /></ProtectedRoute>} />
        <Route path="editar-caminhao/:id" element={<ProtectedRoute><EditarCaminhao /></ProtectedRoute>} />
        <Route path="deletar-caminhao/:id" element={<ProtectedRoute><DeletarCaminhao /></ProtectedRoute>} />
        <Route path="cadastrar-carreta" element={<ProtectedRoute><CadastrarCarreta /></ProtectedRoute>} />
        <Route path="editar-carreta/:id" element={<ProtectedRoute><EditarCarreta /></ProtectedRoute>} />
        <Route path="deletar-carreta/:id" element={<ProtectedRoute><DeletarCarreta /></ProtectedRoute>} />
        <Route path="cadastrar-carro" element={<ProtectedRoute><CadastrarCarro /></ProtectedRoute>} />
        <Route path="editar-carro/:id" element={<ProtectedRoute><EditarCarro /></ProtectedRoute>} />
        <Route path="deletar-carro/:id" element={<ProtectedRoute><DeletarCarro /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
