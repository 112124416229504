import React, { useRef } from "react"
import "./Carousel.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

export const Carousel = () => {
  const sliderRef = useRef(null);

  return (
    <div className="carousel">
      <div className="carousel--button">
        <button
          className="arrow__prev"
          onClick={() => { sliderRef.current.slickPrev(); }}
        >
          <RiArrowLeftSLine className="arrow--icon" />
        </button>
        <button
          className="arrow__next"
          onClick={() => { sliderRef.current.slickNext(); }}
        >
          <RiArrowRightSLine className="arrow--icon" />
        </button>
      </div>
      <div className="carousel--slider">
        <Slider
          ref={sliderRef}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
          autoplay={true}
          dots={false}
          speed={2000}
          autoplaySpeed={4000}
          pauseOnHover={true}
        >
          <div className="carousel--one">
            <div className="carousel--container">
              <div className="carousel--information">
                <p className="carousel--title">
                  SONHANDO COM O SEU CAMINHÃO?
                </p>
                <span className="carousel--text">
                  Aqui seu sonho se torna realidade! Todas as ofertas com garantia de procedência.
                </span>
              </div>
            </div>
          </div>
          <div className="carousel--two">
            <div className="carousel--container">
              <div className="carousel--information">
                <p className="carousel--title">
                  MAIS DE 16 ANOS NO MERCADO
                </p>
                <span className="carousel--text">
                  Fale com a gente! Temos os melhores veículos, as melhores taxas e ótimas condições de pagamento...
                </span>
              </div>
            </div>
          </div>
          <div className="carousel--three">
            <div className="carousel--container">
              <div className="carousel--information">
                <p className="carousel--title">
                  VEÍCULOS E CIA MT
                </p>
                <span className="carousel--text">
                  Aqui a satisfação e a certeza de bons negócios são garantidas!
                </span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}
