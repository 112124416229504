import React, { useState, useEffect } from "react";
import "./AreaUsuario.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegEdit, FaRegTrashAlt, FaShoppingCart } from "react-icons/fa";
import api from "../../config/configApi";
import { BsTruckFlatbed, BsSpeedometer2 } from "react-icons/bs";
import { GiGearStickPattern } from "react-icons/gi";
import { TfiPaintRoller } from "react-icons/tfi";
import { MdSell } from "react-icons/md";
import { PiTruck } from "react-icons/pi";

import Sidebar from "../../components/Sidebar";
import toast, { Toaster } from "react-hot-toast";

import spinner from "../../assets/images/ring-resize.svg";

function AreaUsuario() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [veiculos, setVeiculos] = useState({
        IdVeiculo: "",
        IdCategoria: "",
        NomeCategoria: "",
        IdMarca: "",
        NomeMarca: "",
        AnoFabricacao: "",
        AnoModelo: "",
        Tipo: "",
        Modelo: "",
        Direcao: "",
        Cor: "",
        Combustivel: "",
        Cambio: "",
        Tracao: "",
        Carroceria: "",
        Km: "",
        Valor: "",
        Observacao: "",
        Imagem: "",
        Pic: "",
        Oferta: "",
        CreatedAt: "",
        IdView: "",
        IdVeiculo: "",
        Qtd: ""
    });
    const navigate = useNavigate();

    // USEEFFECT PARA GET ALL VEHICLES
    useEffect(() => {
        const getVeiculos = async () => {
            try {
                const response = await api.get("/api/veiculos/getVeiculos/" + id);
                return response.data;
            } catch (err) {
                toast.error(err.response.data);
                localStorage.clear();
                setTimeout(() => navigate("/login"), 3000);
            }
        }

        const fetchBoth = async () => {
            setLoading(true);
            const vehicles = await getVeiculos();
            setVeiculos(vehicles);
            setLoading(false);
        }

        fetchBoth();
    }, [])

    return (
        <>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                />
            </div>
            <Sidebar id={id} />
            <div className="areaUsuario">
                <div className="areaUsuario__container">
                    <div className="areaUsuario__right">
                        <div className="cardAdmin__top">
                            <h1 className="cardAdmin__title">
                                <span><MdSell /></span>Estoque
                            </h1>
                        </div>
                        <div className="box anuncios">
                            {loading &&
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={spinner} alt="Loading..." />
                                </div>
                            }

                            {!loading && veiculos.length > 0 &&
                                veiculos.map((value) => {

                                    function valor() {
                                        let valor = value.Valor.toString();

                                        if (valor == 1) {
                                            valor = "Consulte";
                                            return valor;
                                        } else {
                                            if (valor.length === 6) {
                                                const a = valor.slice(0, 3);
                                                const b = valor.slice(3, valor.length + 1);
                                                valor = a.concat(".", b);
                                            }

                                            if (valor.length === 5) {
                                                const a = valor.slice(0, 2);
                                                const b = valor.slice(2, valor.length + 1);
                                                valor = a.concat(".", b);
                                            }
                                            return "R$ " + valor + ",00";
                                        }
                                    }

                                    function transformData() {
                                        let data = value.CreatedAt;

                                        const year = data.slice(0, 4);
                                        const month = data.slice(5, 7);
                                        const day = data.slice(8, 10);
                                        data = day.concat("/", month, "/", year)

                                        return data;
                                    }

                                    function transformKm() {
                                        let km = value.Km.toString();

                                        if (km == 1) {
                                            km = "Consulte";
                                            return km;
                                        } else {
                                            if (km.length === 7) {
                                                const a = km.slice(0, 1);
                                                const b = km.slice(1, 4);
                                                const c = km.slice(4, km.length + 1);
                                                km = a.concat(".", b, ".", c);
                                            }

                                            if (km.length === 6) {
                                                const a = km.slice(0, 3);
                                                const b = km.slice(3, km.length + 1);
                                                km = a.concat(".", b);
                                            }

                                            if (km.length === 5) {
                                                const a = km.slice(0, 2);
                                                const b = km.slice(2, km.length + 1);
                                                km = a.concat(".", b);
                                            }
                                            return km + "km";
                                        }
                                    }

                                    async function handleSwitch(vehicle) {
                                        console.log("Oferta veiculo: " + vehicle.IdVeiculo);
                                        await api.put(`api/veiculos/updateOferta/${vehicle.IdVeiculo}`, vehicle)
                                            .then(response => {
                                                toast.success(response.data.msg);
                                                setTimeout(() => window.location.reload(), 2000);
                                            })
                                            .catch(err => {
                                                if (err.response) {
                                                    toast.error(err.response.data);
                                                    localStorage.clear();
                                                    setTimeout(() => navigate("/login"), 3000);
                                                } else {
                                                    console.log(err);
                                                    toast.error("Erro: Tente novamente mais tarde!");
                                                }
                                            });
                                    }

                                    if (value.IdCategoria === 1) {
                                        return (
                                            <div className="cardAdmin" key={value.IdVeiculo}>
                                                <div className="cardAdmin__container">
                                                    <div className="cardAdmin__left">
                                                        <img
                                                            src={value.Imagem}
                                                            alt={value.Modelo}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = "/default-thumb.png";
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="cardAdmin__right">
                                                        <span className="cardAdmin__data">{transformData()}</span>
                                                        <h2 className="cardAdmin__titulo">{value.NomeMarca} {value.Modelo} {value.AnoFabricacao}/{value.AnoModelo}</h2>
                                                        <h1 className="cardAdmin__valor"> {valor()}</h1>
                                                        <div className="cardAdmin__info">
                                                            <p className="single__info"><BsSpeedometer2 />{transformKm()}</p>
                                                            <p className="single__info"><BsTruckFlatbed />{value.Tracao}</p>
                                                            <p className="single__info"><PiTruck />{value.Carroceria}</p>
                                                            <p className="single__info"><GiGearStickPattern />{value.Cambio}</p>
                                                            <p className="single__info"><TfiPaintRoller />{value.Cor}</p>
                                                        </div>
                                                        <div className="cardAdmin__btngroup">
                                                            <button className="cardAdmin__btn sale">
                                                                <FaShoppingCart />
                                                                <span className="span__txt">Oferta</span>
                                                                <label htmlFor={value.IdVeiculo} className="switch">
                                                                    <input onChange={() => handleSwitch(value)} type="checkbox" id={value.IdVeiculo} checked={value.Oferta == 1 ? "checked" : ""} />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </button>
                                                            <button onClick={() => navigate(`/editar-caminhao/${value.IdVeiculo}`)} className="cardAdmin__btn">
                                                                <FaRegEdit />
                                                                <span className="span__txt">Editar</span>
                                                            </button>
                                                            <button onClick={() => navigate(`/deletar-caminhao/${value.IdVeiculo}`)} className="cardAdmin__btn danger">
                                                                <FaRegTrashAlt />
                                                                <span className="span__txt">Excluir</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="cardAdmin__view">
                                                        {value.Qtd} Acesso(s)
                                                    </div>
                                                </div>
                                                <span className="cardAdmin__border"></span>
                                            </div>
                                        )
                                    } else if (value.IdCategoria === 2) {
                                        return (
                                            <div className="cardAdmin" key={value.IdVeiculo}>
                                                <div className="cardAdmin__container">
                                                    <div className="cardAdmin__left">
                                                        <img
                                                            src={value.Imagem}
                                                            alt={value.Modelo}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = "/default-thumb.png";
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="cardAdmin__right">
                                                        <span className="cardAdmin__data">{transformData()}</span>
                                                        <h2 className="cardAdmin__titulo">{value.Tipo} {value.NomeMarca} {value.AnoFabricacao}/{value.AnoModelo}</h2>
                                                        <h1 className="cardAdmin__valor"> {valor()}</h1>
                                                        <div className="cardAdmin__info">
                                                            <p className="single__info"><BsTruckFlatbed />{value.Modelo}</p>
                                                            <p className="single__info"><TfiPaintRoller />{value.Cor}</p>
                                                        </div>
                                                        <div className="cardAdmin__btngroup">
                                                            <button className="cardAdmin__btn sale">
                                                                <FaShoppingCart />
                                                                <span className="span__txt">Oferta</span>
                                                                <label htmlFor={value.IdVeiculo} className="switch">
                                                                    <input onChange={() => handleSwitch(value)} type="checkbox" id={value.IdVeiculo} checked={value.Oferta == 1 ? "checked" : ""} />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </button>
                                                            <button onClick={() => navigate(`/editar-carreta/${value.IdVeiculo}`)} className="cardAdmin__btn">
                                                                <FaRegEdit />
                                                                <span className="span__txt">Editar</span>
                                                            </button>
                                                            <button onClick={() => navigate(`/deletar-carreta/${value.IdVeiculo}`)} className="cardAdmin__btn danger">
                                                                <FaRegTrashAlt />
                                                                <span className="span__txt">Excluir</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="cardAdmin__view">
                                                        {value.Qtd} Acesso(s)
                                                    </div>
                                                </div>
                                                <span className="cardAdmin__border"></span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="cardAdmin" key={value.IdVeiculo}>
                                                <div className="cardAdmin__container">
                                                    <div className="cardAdmin__left">
                                                        <img
                                                            src={value.Imagem}
                                                            alt={value.Modelo}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = "/default-thumb.png";
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="cardAdmin__right">
                                                        <span className="cardAdmin__data">{transformData()}</span>
                                                        <h2 className="cardAdmin__titulo">{value.NomeMarca} {value.Modelo} {value.AnoFabricacao}/{value.AnoModelo}</h2>
                                                        <h1 className="cardAdmin__valor"> {valor()}</h1>
                                                        <div className="cardAdmin__info">
                                                            <p className="single__info"><BsSpeedometer2 />{transformKm()}</p>
                                                            <p className="single__info"><GiGearStickPattern />{value.Cambio}</p>
                                                            <p className="single__info"><TfiPaintRoller />{value.Cor}</p>
                                                        </div>
                                                        <div className="cardAdmin__btngroup">
                                                            <button className="cardAdmin__btn sale">
                                                                <FaShoppingCart />
                                                                <span className="span__txt">Oferta</span>
                                                                <label htmlFor={value.IdVeiculo} className="switch">
                                                                    <input onChange={() => handleSwitch(value)} type="checkbox" id={value.IdVeiculo} checked={value.Oferta == 1 ? "checked" : ""} />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </button>
                                                            <button onClick={() => navigate(`/editar-carro/${value.IdVeiculo}`)} className="cardAdmin__btn">
                                                                <FaRegEdit />
                                                                <span className="span__txt">Editar</span>
                                                            </button>
                                                            <button onClick={() => navigate(`/deletar-carro/${value.IdVeiculo}`)} className="cardAdmin__btn danger">
                                                                <FaRegTrashAlt />
                                                                <span className="span__txt">Excluir</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="cardAdmin__view">
                                                        {value.Qtd} Acesso(s)
                                                    </div>
                                                </div>
                                                <span className="cardAdmin__border"></span>
                                            </div>
                                        )
                                    }
                                })
                            }

                            {!loading && veiculos.length === 0 &&
                                <div>
                                    <div>Não há registros cadastrados.</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AreaUsuario;
